var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"酒水品名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"库存"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'quantity',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'quantity',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"min":1}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'typeInv',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'typeInv',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"disabled":true},on:{"change":_vm.onTypeChange}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"子类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'typeSub',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'typeSub',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"disabled":true}},_vm._l((_vm.subTypeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'unit',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'unit',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"disabled":true}},_vm._l((_vm.unitList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"单价(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'unitPrice',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'unitPrice',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat,"min":0}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }